import React, { useEffect, useState } from 'react'
import { LayoutContainer } from '../../Components/LayoutContainer/LayoutContainer'
import { ProductTypeDetails } from '../../Components/ProductTypeDetails/ProductTypeDetails';
import { useLocation, useParams } from 'react-router-dom';
import { APIRequest, ApiUrl } from '../../utils/api';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

export const ProductTypePage = () => {
  const { typeId } = useParams();
  const location = useLocation();
  const StoreData = useSelector(state => state.user.storeData)
  const [ProductList, setProductList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  console.log("storeId", StoreData?._id,);
  console.log("categoryId", typeId);


  const GetProduct = () => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.getProductByFilter}`,
      method: "post",
      body: {
        "page": 1,
        "limit": 40,
        // "isTopRated":true,
        // "isVeg":false,
        "storeId": StoreData?._id,
        "categoryId": typeId
      },
    };
    APIRequest(
      config,
      (res) => {
        console.log('getProductByFilter----', res);
        setProductList(res?.data)
        setIsLoading(false);
      },
      (err) => {
        // console.log(err.message, "---err");
        setIsLoading(false)
        if (err?.message) {
          toast.error(err?.message)
        }
      }
    );
  }


  useEffect(() => {
    GetProduct()
  }, [])

  console.log('StoreData======', StoreData);

  // Function to get query parameters
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };

  const query = useQuery();
  const name = query.get('name');

  console.log(name, typeId);



  return (
    <>
      <LayoutContainer>
        <ProductTypeDetails name={name} typeId={typeId} ProductList={ProductList} isLoading={isLoading} />
      </LayoutContainer>
    </>
  );
}
