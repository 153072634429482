import axios from "axios";
// import ClearSession from "./ClearSession";

export const BASEURL = "https://api.penguinexp.com";
const apiBaseUrl = `${BASEURL}/api/`;
const APIUser = `${apiBaseUrl}user/`;
// const APISuperadmin = `${apiBaseUrl}superAdmin/`
// const APIBank = `${apiBaseUrl}bank/`

export const ApiUrl = {
  signup: `${APIUser}signup`,
  accountVerification: `${APIUser}account/verification`,
  login: `${APIUser}login`,
  forgotPassword: `${APIUser}forgot/password`,
  resetPassword: `${APIUser}reset/password`,
  changePassword: `${APIUser}change/password`,
  userDetails: `${APIUser}get/details`,
  updateProfile: `${APIUser}update/profile`,

  getProduct: `${APIUser}product/get/all/web`,
  getProductByFilter: `${APIUser}product/get/all/category`,
  getProductDetails: `${APIUser}product/get/By/productId`,

  // getCategory: `${apiBaseUrl}category/get/all`,
  getCategory: `${apiBaseUrl}store/category/get/all`,
  getBanner: `${apiBaseUrl}banner/get/all`,
  getStore: `${apiBaseUrl}store/get/nearest/store`,
  getStoreCategory: `${apiBaseUrl}store/get/all/category`,

  // Cart
  getCart: `${apiBaseUrl}user/cart/get/by/userId`,
  addCart: `${apiBaseUrl}user/cart/add/product`,
  deleteCart: `${apiBaseUrl}user/cart/delete`,
  deleteProductCart: `${apiBaseUrl}user/cart/remove/product`,

  //Feedback
  feedbackAdd: `${apiBaseUrl}feedback/add`,

  // Order
  placeOrder: `${apiBaseUrl}order/place`,
  myOrder: `${apiBaseUrl}order/get/all/by/userId`,

  // Address
  getAddress: `${apiBaseUrl}user/address/get`,
  removeAddress: `${apiBaseUrl}user/address/delete`,
  addAddress: `${apiBaseUrl}user/address/add`,

  // Tip
  getTip: `${apiBaseUrl}tip/get/all`,

  // Delivery Charge
  getDeliveryCharge: `${apiBaseUrl}delivery/range/get/all`,

  //Search
  Search: `${APIUser}product/search`,

  //Wallet
  getwalletTransaction: `${apiBaseUrl}order/get/all/by/wallet`,

  //Paypal
  transaction_create: `${apiBaseUrl}transaction/create`,
  paymentSucess: `${apiBaseUrl}transaction/success`,
  transaction_cancel: `${apiBaseUrl}transaction/cancel`,

  //Wallet
  walletTransaction_create: `${apiBaseUrl}transaction/popup/create`,
  walletPaymentSuccess: `${apiBaseUrl}transaction/popup/success`,
  walletTransaction_cancel: `${apiBaseUrl}transaction/popup/cancel`,
  getWalletBalance: `${apiBaseUrl}user/get/details`,

  //coupon
  getCuponForUser: `${apiBaseUrl}coupon/get/all/user`,

  //getLanguage
  getLanguage: `${apiBaseUrl}language/get/all`
};

// export const APIRequest = async (config = {}, onSuccess, onError, noAuth = null) => {

//   const token = JSON.parse(localStorage?.getItem('data'));

//   try {
//     let data = {};
//     if (token && noAuth == null) {
//       data = {
//         method: config.method,
//         url: config.url,
//         data: config.body,
//         // timeout: 180000, // Wait for 5 seconds
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//       };
//     } else {
//       data = {
//         method: config.method,
//         url: config.url,
//         data: config.body,
//         headers: {
//           Accept: 'application/json',
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${token}`,
//         },
//       };
//     }
//     // console.log(data);
//     axios(data)
//       .then(res => {
//         if (!res?.data?.error) {
//           onSuccess(res?.data);
//         } else {
//           onError(res?.data ? res.data : res);
//         }
//       })
//       .catch(err => {
//         console.log(err, 'catch-- ===============');
//         // console.log(err?.response?.data, 'catch-- ===============');
//         if (err?.response?.data?.statusCode === 2) {
//           window.location.href = "packers-movers";
//           localStorage.setItem('validated', JSON.stringify(err?.response?.data?.statusCode))
//         }
//         onError(err?.response?.data ? err?.response.data : err?.response);
//       });
//   } catch (error) {
//     console.log("error", error);
//   }
// };

export const APIRequest = async (
  config = {},
  onSuccess,
  onError,
  noAuth = null
) => {
  // const token = ""
  const token = await localStorage?.getItem("token");
  try {
    let data = {};
    if (token && noAuth == null) {
      data = {
        method: config.method,
        url: config.url,
        data: config.body,
        headers: {
          // Accept: 'application/json',
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          // token: token
        },
      };
    } else {
      data = {
        method: config.method,
        url: config.url,
        data: config.body,
        headers: {
          // Accept: 'application/json',
          "Content-Type": "application/json",
        },
      };
    }
    console.log("@@@@@@@@@@@@@@@@", data);
    axios(data)
      .then((res) => {
        // console.log("sd", res);
        if (!res.data.error) {
          onSuccess(res?.data);
        } else {
          onError(res?.data ? res.data : res);
        }
      })
      .catch((err) => {
        console.log(err);
        onError(err?.response?.data ? err?.response?.data : err?.response);
      });
  } catch (error) {
    console.log("error", error);
  }
};

export const APIRequestWithFile = async (config = {}, onSuccess, onError) => {
  const token = await localStorage?.getItem("token");

  try {
    let data;
    if (token) {
      data = {
        method: config.method,
        url: config.url,
        data: config.body,
        headers: {
          Accept: "multipart/form-data",
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };
    } else {
      data = {
        method: config.method,
        url: config.url,
        data: config.body,
        headers: {
          Accept: "multipart/form-data",
          "Content-Type": "multipart/form-data",
        },
      };
    }

    console.log("config", data);
    axios(data)
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          // console.log(res.data);
          onSuccess(res.data);
        }
      })
      .catch((err) => {
        onError(err?.response?.data, "===============");
        if (err?.response?.data?.statusCode === 2) {
          window.location.href = "";
          localStorage.setItem(
            "validated",
            JSON.stringify(err?.response?.data?.statusCode)
          );
        }
      });
  } catch (error) {
    console.log(error, "-----------------");
  }
};
