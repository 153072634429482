import React, { useEffect, useState } from "react";
import { FaRegHeart } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { APIRequest, ApiUrl } from "../../utils/api";
import { toast } from "react-toastify";
import { Loader } from "../../Components/Loader/Loader";
import { useSelector } from "react-redux";

export const ProductType = () => {
  const navigation = useNavigate();
  const [CategoryList, setCategoryList] = useState([]);
  // const [CategoryDetails, setCategoryDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = localStorage.getItem("token");
  const StoreData = useSelector((state) => state.user.storeData);

  // console.log("tokentoken", token);

  const GetCategory = () => {
    const storeId = StoreData?._id;

    // console.log("storeIdstoreIdstoreIdstoreId", storeId);

    setIsLoading(true);
    let config = {
      url: `${ApiUrl.getCategory}`,
      method: "post",
      body: {
        storeId: storeId,
      },
    };
    APIRequest(
      config,
      (res) => {
        // console.log("jajshjdahjkdhskjaheee", res?.data);
        setIsLoading(false);

        setCategoryList(res?.data);
      },
      (err) => {
        console.log(err.message, "---err");
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  useEffect(
    (storeId) => {
      GetCategory();
    },
    [StoreData]
  );

  // console.log("GetAllCategoryDetails", CategoryList);

  return (
    <>
      <div className="container my-5 product_type">
        <div className="fs-4 fw-bold text-grey mb-2">
          What are you craving for ?
        </div>
        {/* <div className="row row-cols-1 row-cols-md-4 row-cols-sm-2 row-cols-xs-1 g-5 product_type_container"> */}
        <div className="product_type_container mt-3">
          {isLoading ? (
            <Loader isLoading={isLoading} />
          ) : (
            <>
              {CategoryList.length > 0 ? (
                CategoryList?.map((item) => (
                  <div className="col cardd" key={item?._id}>
                    <div className="card">
                      <Link
                        to={
                          token
                            ? `/product-type/${item?._id}?name=${item?.name}`
                            : `/sign-in`
                        }
                        // to="/product-type"
                        className="text-decoration-none"
                      >
                        <img
                          src={item?.image}
                          className="card-img-top"
                          alt="..."
                          style={{ height: "25vh" }}
                        />
                      </Link>
                      <div className="card-body">
                        <h5 className="card-title fw-bold d-flex justify-content-center">
                          {item?.name || "Category Name"}
                        </h5>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-md-12 text-center text-grey fw-bold fs-4">
                  No Category Found
                </div>
              )}
            </>
          )}
        </div>
        {/* <Loader isLoading={isLoading} /> */}
      </div>
    </>
  );
};
