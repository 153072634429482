import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import Modal from "react-modal";
import { FaSearch } from "react-icons/fa";
import { IoMdLocate } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { SlMenu } from "react-icons/sl";
import Select from "react-select";
import "bootstrap/dist/css/bootstrap.min.css";
import "flag-icon-css/css/flag-icons.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { APIRequest, ApiUrl } from "../../utils/api";
import { toast } from "react-toastify";
import {
  setStoreData,
  setUserData,
  clearCart,
} from "../../app/slice/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../../app/slice/CartSlice";

const customStyles = {
  content: {
    top: "45%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "370px",
    height: "50vh",
    marginRight: "0",
    zIndex: "99",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    border: "none",
  },
};

export const Navbar = () => {
  const handlerLogOut = () => {
    localStorage.removeItem("token");
    dispatch(setUserData(null));
    setIsLoggedIn(false);
    window.location.href = "/";
  };
  const CartData = useSelector((state) => state.cart.cartData);
  const dispatch = useDispatch();
  const StoreData = useSelector((state) => state.user.storeData);
  const [nearestStore, setnearestStore] = useState([]);
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [profileVisible, setProfileVisible] = useState(false);
  const [Data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [location, setLocation] = useState({ lat: null, lng: null });
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [storeInfo, setstoreInfo] = useState("");

  const SendRequest = () => {
    let token = localStorage.getItem("data");

    if (token) {
      setIsLoading(true);
      let config = {
        url: `${ApiUrl.userDetails}`,
        method: "get",
      };
      APIRequest(
        config,
        (res) => {
          dispatch(setUserData(res?.user));
          SendRequest1(
            res?.user?.location?.coordinates[1],
            res?.user?.location?.coordinates[0]
          );
          if (!StoreData) {
            dispatch(setStoreData(res?.storeFound));
          }
        },
        (err) => {
          setIsLoading(false);
          if (err?.message) {
            toast.error(err?.message);
          }
        }
      );
    } else {
      getLocation();
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      // setIsLoading(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          SendRequest1(latitude, longitude);
          setLocation({ lat: latitude, lng: longitude });
          setIsLoading(false);
        },
        (error) => {
          setError(error.message);
          setIsLoading(false);
        }
      );
    } else {
      // setError("Geolocation is not supported by this browser.");
    }
  };

  const SendRequest1 = (latitude, longitude) => {
    setIsLoading(true);
    let config = {
      url: `${ApiUrl.getStore}`,
      method: "post",
      body: {
        longitude: longitude,
        latitude: latitude,
      },
    };
    APIRequest(
      config,
      (res) => {
        setnearestStore(res?.data);
        // console.log("nearest store",res?.data);
      },
      (err) => {
        setIsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  const deleteCert = () => {
    // console.log("storeInfo",storeInfo);
    // console.log("storeInfostoreInfo",CartData?._id);
    dispatch(setStoreData(storeInfo));
    setIsLoading(true);
    if (CartData?._id) {
      let config = {
        url: `${ApiUrl.deleteCart}/${CartData?._id}`,
        method: "DELETE",
      };
      // console.log("delete cart", config);
      APIRequest(
        config,
        (res) => {
          // console.log("gfhgfjffuutdu", res);
          dispatch(setCart([]));
          setmodalIsOpen(false);
          setIsLoading(false);
        },
        (err) => {
          setIsLoading(false);
          if (err?.message) {
            toast.error(err?.message);
          }
        }
      );
    } else {
      dispatch(setCart([]));
      setmodalIsOpen(false);
      setIsLoading(false);
    }
  };

  const ChangeStore = (id) => {
    console.log("");

    let data = nearestStore.find((item) => item?._id === id);
    if (data?.name) {
      setstoreInfo(data);
      setmodalIsOpen(true);
      // dispatch(setStoreData(data));
    }
  };

  useEffect(() => {
    SendRequest();
    const token = localStorage.getItem("token");
    setIsLoggedIn(!!token);
  }, []);

  // useEffect(() => {
  //   if (StoreData?.location.coordinates[0]) {
  //     SendRequest1()
  //   }
  // }, [StoreData])

  const toggleProfileVisibility = () => {
    setProfileVisible(!profileVisible);
  };
  function openModal() {
    setmodalIsOpen(true);
  }
  function closeModal() {
    setmodalIsOpen(false);
  }

  const cartCount = CartData; // Number of products in the cart
  // console.log("cartCount",cartCount?.products?.length);

  // console.log("isLoggedInisLoggedInisLoggedIn", isLoggedIn);
  // console.log("ahbdjhbsdh jhshd", nearestStore);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light py-3">
        <div className="container-fluid mx-5">
          <Link className="navbar-brand me-5" to="/">
            <img src="/assets/images/PenguinExpress.png" alt="" width="60px" />
            {/* Penguin Express */}
          </Link>
          <button
            className="navbar-toggler shadow-none"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="nav-item dropdown me-2">
              <select
                className="form-select shadow-none cursor-pointer"
                aria-label="Default select example"
                onChange={(e) => ChangeStore(e.target.value)}
              >
                {/* <option selected>Select Store</option> */}
                {/* <option selected>{StoreData?.name} (Selected)</option> */}
                {nearestStore?.map((item, index) => (
                  <option
                    key={item?._id}
                    value={item?._id}
                    selected={item?.name === StoreData?.name}
                  >
                    {item?.name}
                  </option>
                ))}
              </select>
            </div>

            <Link className="nav-link" to="/product-search">
              <div className="d-flex align-items-center me-2 ">
                <div
                  className="input-group rounded"
                  style={{
                    maxWidth: "130px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    cursor: "pointer",
                  }}
                >
                  <span
                    className="input-group-text bg-white border-0 text-muted"
                    id="search-icon"
                  >
                    <FaSearch />
                  </span>
                  {/* <input
                    className="form-control border-0 shadow-none no-clear-icon"
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="search-icon"
                    disabled
                    style={{
                      backgroundColor: "white",
                      cursor: "pointer",
                    }}
                  /> */}
                  <span className="input-group-text bg-white border-0 text-muted">
                    Search
                  </span>
                </div>
              </div>
            </Link>

            <ul className="navbar-nav ms-auto mb-2 mb-lg-0 d-flex align-items-center">
              {isLoggedIn ? (
                <li className="nav-item">
                  <Link className="nav-link" to="/cart">
                    Cart
                    <sup>
                      {cartCount?.products?.length > 0 && (
                        <span className="badge bg-danger ms-2">
                          {cartCount?.products?.length}
                        </span>
                      )}
                    </sup>
                  </Link>
                </li>
              ) : null}
              {!isLoggedIn ? (
                <li className="nav-item">
                  <Link className="nav-link" to="/sign-in">
                    Login
                  </Link>
                </li>
              ) : null}
              {/* language */}
              <li className="nav-item">
                <Select
                  options={options}
                  defaultValue={defaultOption}
                  styles={{
                    control: (base) => ({
                      ...base,
                      maxWidth: "150px",
                      border: "1px solid black",
                      boxShadow: "none",
                      cursor: "pointer",
                      "&:hover": {
                        border: "1px solid black",
                        boxShadow: "none",
                      },
                    }),
                  }}
                />
              </li>
              {isLoggedIn ? (
                <li className="nav-item position-relative ms-2">
                  <div className="" style={{ cursor: "pointer" }}>
                    <SlMenu
                      className="fs-5 fw-bolder"
                      onClick={toggleProfileVisibility}
                    />
                  </div>
                  <div
                    className="position-absolute rounded-1"
                    style={{
                      backgroundColor: "white",
                      display: `${profileVisible ? "block" : "none"}`,
                      right: "0",
                      top: "48px",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                  >
                    <ul className="px-4 py-2">
                      <li className="d-flex">
                        <Link
                          className="dropdown-item flex-grow-1 py-1"
                          to="/my-address"
                        >
                          My Address
                        </Link>
                      </li>
                      <li className="d-flex">
                        <Link
                          className="dropdown-item flex-grow-1 py-1"
                          to="/account"
                        >
                          Profile
                        </Link>
                      </li>
                      <li className="d-flex">
                        <Link
                          className="dropdown-item flex-grow-1 py-1"
                          to="/my-orders"
                        >
                          My Orders
                        </Link>
                      </li>
                      <li className="d-flex">
                        <Link
                          className="dropdown-item flex-grow-1 py-1"
                          to="/wallet"
                        >
                          Wallet
                        </Link>
                      </li>
                      <li className="d-flex">
                        <Link
                          className="dropdown-item flex-grow-1 py-1 shadow-none"
                          to="/feedback"
                        >
                          Feedback
                        </Link>
                      </li>
                      {/* <li className="d-flex">
                        <Link
                          className="dropdown-item flex-grow-1 py-1 shadow-none"
                          to="/reward"
                        >
                          Reward
                        </Link>
                      </li> */}
                      <li className="d-flex">
                        {/* <button className="nav-link" onClick={handlerLogOut}>
                          Logout
                        </button> */}
                        <Link
                          className="dropdown-item flex-grow-1 py-1 shadow-none"
                          to="#"
                          onClick={handlerLogOut}
                        >
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              ) : null}

              {/* <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  to="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <SlMenu className="fs-5 fw-bolder" />
                </Link>
                <ul
                  className="dropdown-menu dropdown-menu-end border-0 mt-4"
                  style={{
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                  }}
                  aria-labelledby="navbarDropdown"
                >
                  <li className="d-flex">
                    <Link
                      className="dropdown-item flex-grow-1 py-2"
                      to="/my-address"
                    >
                      My Address
                    </Link>
                  </li>
                  <li className="d-flex">
                    <Link
                      className="dropdown-item flex-grow-1 py-2"
                      to="/account"
                    >
                      Profile
                    </Link>
                  </li>
                  <li className="d-flex">
                    <Link
                      className="dropdown-item flex-grow-1 py-2"
                      to="/my-orders"
                    >
                      My Orders
                    </Link>
                  </li>
                  <li className="d-flex">
                    <Link
                      className="dropdown-item flex-grow-1 py-2"
                      to="/feedback"
                    >
                      Feedback
                    </Link>
                  </li>
                  <li className="d-flex">
                    <Link
                      className="dropdown-item flex-grow-1 py-2"
                      to="/wishlist"
                    >
                      Wishlist
                    </Link>
                  </li>
                </ul>
              </li> */}

              <Modal
                isOpen={modalIsOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div className="d-flex justify-content-end">
                  <MdClose
                    className="fs-3"
                    onClick={() => setmodalIsOpen(false)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className="fs-5 fw-bold">Warning !</div>
                <p>While Changing Store Cart Will be Empty, Are you Sure ?</p>

                <div className="d-flex justify-content-center mt-4">
                  <button
                    className="btn btn-danger f-6 py-1 px-3 border-none outline-none rounded-pill me-2 shadow-none"
                    onClick={() => deleteCert()}
                  // disabled={isLoading}
                  >
                    {isLoading ? " Processing..." : "Ok"}
                  </button>
                </div>
              </Modal>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

const stores = [
  { label: "Zero", value: "Zero" },
  { label: "One", value: "One" },
  { label: "Two", value: "Two" },
  { label: "Three", value: "Three" },
];

const options = [
  {
    value: "en",
    label: (
      <div>
        <span className="flag-icon flag-icon-us"></span> English
      </div>
    ),
  },
  {
    value: "hi",
    label: (
      <div>
        <span className="flag-icon flag-icon-in"></span> हिंदी
      </div>
    ),
  },
  {
    value: "fr",
    label: (
      <div>
        <span className="flag-icon flag-icon-fr"></span> French
      </div>
    ),
  },
  {
    value: "zh",
    label: (
      <div>
        <span className="flag-icon flag-icon-cn"></span> Chinese
      </div>
    ),
  },
];
const defaultOption = options.find((option) => option.value === "en");
