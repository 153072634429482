import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { APIRequest, ApiUrl } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { setCart } from "../../app/slice/CartSlice";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Loader } from "../Loader/Loader";

export const ProductTypeDetails = ({ name, ProductList, isLoading }) => {
  const token = localStorage.getItem("token");
  const StoreData = useSelector((state) => state.user.storeData);
  const cart = useSelector((state) => state.cart.cartData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sLoading, setsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 400;

  const AddToCart = (productId, storeId, quantity = 1) => {
    setsLoading(true);
    let config = {
      url: ApiUrl?.addCart,
      method: "post",
      body: {
        productId: productId,
        storeId: storeId,
        quantity: quantity,
      },
    };
    APIRequest(
      config,
      (res) => {
        setsLoading(false);
        console.log("API Response:", res);
        GetCart();
        toast.success("Product added to cart");
        // if (res?.data) {
        //   console.log("Product Added", res.data[0]?.products);
        //   dispatch(setCart(res.data[0]?.products));
        //   toast.success("Product added to cart");
        // } else {
        //   toast.error('Failed to add product to cart');
        // }
      },
      (err) => {
        setsLoading(false);
        console.error("API Error:", err);
        toast.error(err?.message || "Failed to add product to cart");
      }
    );
  };

  const UpdateCart = (productId, storeId, quantity) => {
    setsLoading(true);
    let config = {
      url: `${ApiUrl.addCart}`,
      method: "post",
      body: {
        productId: productId,
        storeId: storeId,
        quantity: quantity,
      },
    };
    APIRequest(
      config,
      (res) => {
        toast.success(res?.message);
        // dispatch(setCart(res?.created))
        GetCart();
      },
      (err) => {
        setsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  const GetCart = () => {
    setsLoading(true);
    let config = {
      url: `${ApiUrl.getCart}`,
      method: "get",
    };
    APIRequest(
      config,
      (res) => {
        dispatch(setCart(res?.data[0]));
      },
      (err) => {
        setsLoading(false);
        if (err?.message) {
          toast.error(err?.message);
        }
      }
    );
  };

  useEffect(() => {
    if (StoreData?.location.coordinates[0]) {
      GetCart();
    }
  }, [StoreData]);

  const mergeData = () => {
    const cartMap = cart?.products?.reduce((acc, item) => {
      acc[item.productId._id] = item.quantity;
      return acc;
    }, {});
    return ProductList?.map((product) => ({
      ...product,
      itemQuantity: (cartMap && cartMap[product._id]) || 0,
    }));
  };
  const mergedData = mergeData();

  return (
    <div className="container my-5 products">
      <div className="fs-4 fw-bold text-grey mb-2">{name}</div>
      <div className="row row-cols-1 row-cols-md-4 g-5">
        {isLoading ? (
          <Loader isLoading={isLoading} />
        ) : (
          <>
            {
              mergedData.length > 0 ?
                (mergedData?.map((item) => {
                  return (
                    <div className="col" key={item?._id}>
                      <div className="card">
                        <Link
                          to={`/product-details/${item.categoryId}/${item._id}`}
                          className="text-decoration-none"
                        >
                          <img
                            src={item?.images[0].url}
                            className="card-img-top"
                            alt="..."
                            style={{ height: "25vh" }}
                          />
                        </Link>
                        <div className="card-body">
                          <h5 className="card-title">{item?.category}</h5>
                          <p className="card-text">{item?.title}</p>
                          <div className="d-flex justify-content-between">
                            <span className="fw-bold">${item?.price}</span>
                            {item?.itemQuantity < 1 ? (
                              <button
                                type="button"
                                className="btn btn-sm btn-outline-danger shadow-none"
                                onClick={() =>
                                  token
                                    ? AddToCart(item._id, StoreData._id)
                                    : navigate("/sign-in")
                                }
                              >
                                Add to Cart
                              </button>
                            ) : (
                              <div className="clo-lg-4 col-md-6 d-flex account">
                                <button
                                  className="btn btn-link px-2 shadow-none"
                                  onClick={() =>
                                    UpdateCart(item?._id, cart?.storeId, -1)
                                  }
                                >
                                  <FaMinus style={{ color: "red" }} />
                                </button>
                                <input
                                  id="form1"
                                  min="0"
                                  name="quantity"
                                  value={item?.itemQuantity}
                                  type="number"
                                  className="form-control form-control-sm w-25 border-0"
                                  readOnly
                                  style={{
                                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                                  }}
                                />

                                <button
                                  className="btn btn-link px-2 shadow-none"
                                  onClick={() =>
                                    UpdateCart(item?._id, cart?.storeId, 1)
                                  }
                                >
                                  <FaPlus style={{ color: "red" }} />
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })) : (
                  <div className="col-md-12 text-center text-grey fw-bold fs-4">No side item found for this store</div>
                )
            }

          </>
        )}
      </div>
    </div>
  );
};
